<template>
  <div id="sell-bitcoin">
    <TxidInformationModal
      v-show="showTxidInformation"
      @closeModal="toggleTxidInformation"
    />
    <ConfirmSalesRequest
      v-show="confirmSalesRequest"
      @closeModal="handleConfirmSalesRequest"
      :item="item"
      @close="close"
      :total="tomman"
      :amount="amount"
    />
    <SuccessAlert
      title="فروش با موفقیت انجام شد."
      massage=" خرید شمادر لیست سفارشات قرار گرفت. با مراجعه به بخش سفارشات می توانیداز جزئیات خرید خود مطلع شوید."
      v-show="successMessage"
    />
    <div class="bitcoin-container">
      <div class="form-group">
        <div class="amount-of-bitcoin-form-section__header">
          مقدار ارز*
        </div>
        <div class="bitcoin-amount-input">
          <div class="amount-input">
            <div class="amount-input__wrapper" :class="{ error: error.amount }">
              <img :src="item.image" class="amount-input__image" alt="" />
              <label style="width: 100%">
                <input
                  type="text"
                  v-model="amount"
                  @input="setAmount($event)"
                  ref="amount"
                  style="width: 100%; text-align: center"
                />
              </label>
              <div class="amount-input__symbol">
                {{ item ? item.symbol : "----" }}
              </div>
            </div>
            <div class="error-message">
              {{ error.amount }}
            </div>
          </div>
        </div>
      </div>

      <div class="form-group">
        <div class="purchase-price-from-site-wrapper">
          <div class="purchase-price-from-site_header">
            هر دلار {{ item.persian_name }}
          </div>
          <div class="purchase-price-form-site_price">
            <span>{{
              item
                ? Number(item.purchase_price_from_us).toLocaleString()
                : "----"
            }}</span>
            تومان
          </div>
        </div>
      </div>

      <div class="form-group">
        <div class="amount_received-wrapper">
          <div class="amount-received__header">
            مقدار دریافتی
          </div>
          <div class="amount-received__input">
            <span>{{ tomman + " " }}</span
            >تومان
          </div>
        </div>
      </div>

      <div class="form-group" style="margin-top: 40px;">
        <div class="how-to-get-money-wrapper">
          <div class="how-to-get-money__header">
            نحوه دریافت پول
          </div>
          <div class="how-to-get-money__radio-wrapper">
            <div class="how-to-pay__radio">
              <input
                type="radio"
                id="banking-portal"
                name="radio-group"
                value="wallet"
                v-model="picked"
                checked
              />
              <label for="banking-portal">انتقال به کیف پول</label>
            </div>
            <div class="how-to-pay__radio">
              <input
                type="radio"
                id="deposit-to-account"
                name="radio-group"
                value="bank_account"
                v-model="picked"
              />
              <label for="deposit-to-account">واریز به حساب بانکی</label>
            </div>
            <div class="how-to-pay__radio">
              <input
                type="radio"
                id="deposit-to-others-accounts"
                name="radio-group"
                value="other"
                v-model="picked"
              />
              <label for="deposit-to-others-accounts"
                >واریز به حساب دیگران</label
              >
            </div>
          </div>
          <div class="how-to-get-money__information-text">
            واریز به کیف پول داخلی اکس اونیکس به صورت آنی بوده و می توانید از آن
            برای خریدهای بعدی یا تبدیل به سایر ارزها استفاده نمایید.
          </div>
          <div class="how-to-get-money__check-radio-container">
            <TransferToWallet
              @modalChang="toggleConfirmSalesRequest"
              @textArea="handleDescription"
              @onClick="submit"
              v-if="picked === 'wallet'"
              v-model="description"
            />
            <DepositToABankAccount
              @bankSelected="bankCardHandler"
              @onClick="submit"
              v-if="picked === 'bank_account'"
            />
            <DepositToOthersAccounts
              @modalChang="toggleConfirmSalesRequest"
              v-if="picked === 'other'"
              @onClick="submit"
              @cardNumberInput="cardNumberHandler"
              @nameAndFamilyInput="fullnameHandler"
              @bankNameInput="bankNameHandler"
              @shabaNumber="shabaNumberHandler"
              @description="descriptionHandler"
              @accountNumberInput="accountNumberHandler"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TxidInformationModal from "@/components/Modals/TxidInformationModal";
import TransferToWallet from "@/components/TransferToWallet";
import DepositToABankAccount from "@/components/DepositToABankAccount";
import DepositToOthersAccounts from "@/components/DepositToOthersAccounts";
import ConfirmSalesRequest from "@/components/Modals/ConfirmSalesRequest";
import SuccessAlert from "@/components/Modals/SuccessAlert";
import axios from "axios";

export default {
  name: "SellSystemPayment",
  props: {
    item: {
      required: true
    }
  },
  components: {
    ConfirmSalesRequest,
    DepositToOthersAccounts,
    DepositToABankAccount,
    TransferToWallet,
    TxidInformationModal,
    SuccessAlert
  },
  data() {
    return {
      showTxidInformation: false,
      picked: "wallet",
      transferToWallet: null,
      DepositToABankAccount: null,
      DepositToOthersAccounts: null,
      confirmSalesRequest: false,
      successMessage: false,
      amount: null,
      tomman: 0,
      description: null,
      bankCardSelected: null,
      cardNumber: null,
      fullname: null,
      bankName: null,
      shabaNumber: null,
      accountNumber: null,
      error: {}
    };
  },

  methods: {
    cardNumberHandler(value) {
      this.cardNumber = value;
    },
    fullnameHandler(value) {
      this.fullname = value;
    },
    bankNameHandler(value) {
      this.bankName = value;
    },
    shabaNumberHandler(value) {
      this.shabaNumber = value;
    },
    bankCardHandler(item) {
      this.bankCardSelected = item;
    },
    descriptionHandler(item) {
      this.description = item;
    },
    accountNumberHandler(item) {
      this.accountNumber = item;
    },
    async handleConfirmSalesRequest() {
      this.confirmSalesRequest = false;

      if (this.picked === "wallet") {
        let data = {
          amount: this.amount,
          cost: this.tomman,
          description: this.description,
          crypto_id: this.item.id
        };

        let loading = this.$loading.show();
        try {
          let response = await axios({
            url:
              "/panel/order?type=sell&pay_method=wallet&order_method=system_payment",
            method: "POST",
            data: data
          });

          if (!response.data.status) {
            return false;
          }
          this.amount = 0;
          this.tomman = 0;
          loading.hide();

          this.successMessage = true;

          setTimeout(() => {
            this.successMessage = false;
          }, 2000);
        } catch (error) {
          console.log(error);
        }
        loading.hide();
      }

      if (this.picked === "bank_account") {
        let data = {
          amount: this.amount,
          cost: this.tomman,
          description: this.description,
          crypto_id: this.item.id,
          bank_account_id: this.bankCardSelected.id
        };
        let loading = this.$loading.show();
        try {
          let response = await axios({
            url:
              "/panel/order?type=sell&pay_method=bank_account&order_method=system_payment",
            method: "POST",
            data: data
          });
          if (!response.data.status) {
            console.log(response.data);
            return false;
          }
          loading.hide();

          this.successMessage = true;
          this.amount = 0;
          this.tomman = 0;

          setTimeout(() => {
            this.successMessage = false;
          }, 2000);
        } catch (error) {
          console.log(error);
        }
        loading.hide();
      }

      if (this.picked === "other") {
        let data = {
          crypto_id: this.item.id,
          amount: this.amount,
          cost: this.tomman,
          card_number: this.cardNumber,
          fullname: this.fullname,
          bank_name: this.bankName,
          shaba_number: this.shabaNumber,
          account_number: this.accountNumber,
          description: this.description
        };

        let loading = this.$loading.show();
        try {
          let response = await axios({
            url:
              "/panel/order?type=sell&pay_method=other_account&order_method=system_payment",
            method: "POST",
            data: data
          });
          if (!response.data.status) {
            loading.hide();
            console.log(response.data);
            return false;
          }
          loading.hide();

          this.successMessage = true;
          this.voucher_code = null;
          this.activation_code = null;

          setTimeout(() => {
            this.successMessage = false;
          }, 2000);
        } catch (error) {
          console.log(error);
        }
        loading.hide();
      }
    },
    toggleTxidInformation() {
      this.showTxidInformation = !this.showTxidInformation;
    },
    handleDescription(value) {
      this.description = value;
    },
    toggleConfirmSalesRequest() {
      this.showBuyModal = !this.showBuyModal;
      this.showSuccessMassage = false;
      if (!this.showBuyModal) {
        this.showSuccessMassage = true;
        setTimeout(() => (this.showSuccessMassage = false), 2000);
      }
    },
    setAmount(e) {
      // console.log(e);
      let value = typeof e !== "number" ? e.target.value : e,
        data = typeof e !== "number" ? e.data : e,
        reg = /^0$|^[1-9]\d*$|^\.\d+$|^0\.\d*$|^[1-9]\d*\.\d*$/;

      if (value[value.length - 2] === undefined) data = null;

      if (reg.test(value[value.length - 2] + data)) {
        if (value === null) value = "";
        if (!reg.test(value)) value.slice(0, -1);
        if (value === "") value = null;
      } else {
        value = value.replace(data, "");
      }

      this.amount = value;
      this.tomman = (this.item.purchase_price_from_us * value).toLocaleString();
      this.$emit("value", { amount: this.amount, tomman: this.tomman });
    },
    submit() {
      this.error = {};
      if (!this.amount) {
        this.error.amount = "تعداد را وارد کنید";
        this.$refs.amount.focus();
        return false;
      }

      this.confirmSalesRequest = true;
    },
    close() {
      this.confirmSalesRequest = false;
    }
  }
};
</script>

<style scoped>
.bitcoin-container {
  width: 100%;
  /*min-height: 1090px;*/
  border-radius: 10px;
  background: rgba(220, 0, 0, 0.1);
  /*padding: 20px;*/
  padding: 5px;
}

.bitcoin-amount-input {
  width: 100%;
}

.amount-input__wrapper {
  display: flex;
  background: #fff;
  justify-content: space-between;
  padding: 3px 3px 3px 12px;
  border-radius: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
}

input {
  flex-grow: 1;
  padding: 0 10px;
  /* height: 100%; */
  outline: none;
  border: none;
  font-size: 16px;
}

.amount-input__symbol {
  color: rgba(18, 18, 18, 0.7);
}

.amount-input__image {
  width: 22px;
  height: 22px;
}

.amount-of-bitcoin-form-section__header {
  color: #121212;
  font-size: 16px;
  font-family: "Vazir Medium FD";
}

.form-group {
  margin-bottom: 20px;
}

.purchase-price-form-site_price {
  background: rgba(255, 255, 255, 0.5);
  text-align: end;
  padding: 3px 0 3px 27px;
  border-radius: 5px;
  color: rgba(18, 18, 18, 0.7);
}

.amount-received__header {
  font-weight: normal;
  font-size: 16px;
  font-family: "Vazir Medium FD";
}

.amount-received__input {
  width: 100%;
  background: #fff;
  padding: 3px 0 3px 27px;
  border-radius: 5px;
  text-align: left;
}

.exchange-wallet-address__header {
  font-size: 16px;
  font-weight: normal;
}

.exchange-wallet-address__input {
  width: 100%;
  background: rgba(255, 255, 255, 0.8);
  /*padding: 3px 0 3px 27px;*/
  border-radius: 5px;
  padding: 3px;
  text-align: left;
  color: rgba(18, 18, 18, 0.7);
}

.exchange-wallet-address__input span {
  font-size: 12px;
}

.barcode__wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}

.tag-wallet-address__input {
  width: 100%;
  background: rgba(255, 255, 255, 0.8);
  padding: 3px 0 3px 27px;
  border-radius: 5px;
  text-align: left;
  color: rgba(18, 18, 18, 0.7);
}

.transaction-link__header {
  font-size: 16px;
  font-weight: normal;
  font-family: "Vazir Medium FD";
}

.transaction-link__input {
  width: 100%;
  padding: 5px 10px 5px 27px;
  border-radius: 5px;
}

.transaction-link__information-link {
  font-weight: normal;
  font-size: 12px;
  line-height: 19px;
  color: rgba(31, 60, 136, 0.8);
  float: left;
  margin-top: 5px;
  cursor: pointer;
}

.txid-information-modal-wrapper {
  width: 100%;
  height: 100%;
  background: #ffffff;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  position: fixed;
  top: 50%;
  right: 50%;
}

.purchase-price-from-site_header {
  font-weight: normal;
  font-size: 16px;
  font-family: "Vazir Medium FD";
}

.how-to-get-money__radio-wrapper {
  display: flex;
  /*gap: 45px;*/
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
}

.how-to-get-money__header {
  font-weight: normal;
  font-size: 16px;
  font-family: "Vazir Medium FD";
}

.how-to-pay__radio {
  display: flex;
  align-items: center;
  gap: 10px;
}

label {
  font-weight: normal;
  font-size: 14px;
}

.how-to-get-money__information-text {
  font-weight: normal;
  font-size: 12px;
  line-height: 19px;
  margin-top: 10px;
  font-family: "Vazir Medium FD";
}

.error {
  border: 1px solid #ff6b6b;
}

.error-message {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: #ff6b6b;
}

@media (min-width: 720px) {
  .bitcoin-container {
    padding: 20px;
  }
}
</style>
