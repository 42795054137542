<template>
  <div id="buy-bitcoin">
    <ConfirmPurchaseRequest
      v-if="confirmPurchaseModal"
      @closeModal="confirmPurchaseModalHandle"
      :unit-price="
        item ? Number(item.purchase_price_from_us).toLocaleString() : '----'
      "
      :wallet-inventory="
        item ? Number(item.user_wallet_balance).toLocaleString() : '----'
      "
      :total-price="tomman"
      :amount="amount"
    />
    <LackOfInventory
      :amount="amount"
      :total-price="tomman"
      v-if="lackOfInventoryModal"
      @closeModal="lackOfInventoryHandle"
      :unit-price="
        item ? Number(item.purchase_price_from_us).toLocaleString() : '----'
      "
      :wallet-inventory="
        item ? Number(item.user_wallet_balance).toLocaleString() : '----'
      "
    />
    <SuccessAlert
      title="خرید با موفقیت انجام شد."
      massage=" خرید شمادر لیست سفارشات قرار گرفت. با مراجعه به بخش سفارشات می توانیداز جزئیات خرید خود مطلع شوید."
      v-if="successMessageModal"
    />
    <div class="bitcoin-container">
      <div class="amount-of-bitcoin-form-section">
        <div class="amount-of-bitcoin-form-section__header">
          مقدار {{ item ? item.persian_name : "----" }}*
        </div>
        <div class="bitcoin-amount-input">
          <div class="amount-input">
            <div class="amount-input__wrapper" :class="{ error: error.amount }">
              <img
                :src="item ? item.image : '----'"
                class="amount-input__image"
                alt=""
              />
              <label style="width: 100%">
                <input
                  type="text"
                  v-model="amount"
                  @input="setAmount($event)"
                  ref="amount"
                  style="width: 100%; text-align: center;height: 100%"
                />
              </label>
              <div class="amount-input__symbol">
                {{ item ? item.symbol : "----" }}
              </div>
            </div>
          </div>
          <div class="error-message" v-if="error.amount">
            {{ error.amount }}
          </div>
          <div class="amount-input">
            <div class="amount-input__wrapper" :class="{ error: error.tomman }">
              <img
                src="../../assets/images/iran.svg"
                alt=""
                class="amount-input__image"
              />
              <label style="width: 100%">
                <input
                  type="text"
                  v-model="tomman"
                  @input="setTomman($event)"
                  ref="tomman"
                  style="width: 100%; text-align: center; height: 100%"
                />
              </label>
              <div class="amount-input__symbol">تومان</div>
            </div>
          </div>
          <div class="error-message" v-if="error.tomman">
            {{ error.tomman }}
          </div>
        </div>
        <div class="tomman-amount-input"></div>
        <div class="bitcoin-amount-details">
          <div class="bitcoin-amount-details__span">
            موجودی :
            <span
              >btc
              {{
                item ? Number(item.inventory).toLocaleString() : "----"
              }}</span
            >
          </div>
          <div class="bitcoin-amount-details__span">
            موجودی تومانی :
            <span>{{
              item ? Number(item.tommani_inventory).toLocaleString() : "----"
            }}</span>
          </div>
        </div>
      </div>
      <div class="purchase-price-from-site">
        <div class="purchase-price-from-site_header">
          هر دلار {{ item ? item.persian_name : "----" }}
        </div>
        <div class="purchase-price-form-site_price">
          <span>{{
            item ? Number(item.purchase_price_from_us).toLocaleString() : "----"
          }}</span>
          تومان
        </div>
      </div>

      <div class="wallet-address-section">
        <div class="wallet-address-wrapper">
          <div class="wallet-address-wrapper__header">
            آدرس کیف پول
          </div>
          <div class="wallet-address__input-wrapper">
            <label style="width: 100%">
              <input
                :class="{ error: error.wallet_address }"
                style="width: 100%;"
                class="wallet-address-input"
                type="text"
                ref="wallet_address"
                v-model="wallet_address"
              />
            </label>
            <div class="error-message">
              {{ error.wallet_address }}
            </div>
          </div>
        </div>
      </div>

      <div class="asset-description-section">
        <div class="asset-description__header">
          دارای توضیحات
        </div>
        <div class="radio">
          <label>
            <input
              type="radio"
              id="has-description-yes"
              name="has-description"
              value="true"
              v-model="has_description"
            />
          </label>
          <label for="radio">بلی</label>
        </div>
        <div class="radio">
          <label>
            <input
              type="radio"
              id="has-description-no"
              name="has-description"
              value="false"
              v-model="has_description"
            />
          </label>
          <label for="radio">خیر</label>
        </div>
      </div>
      <div class="asset-description__textarea-wrapper">
        <label>
          <textarea
            class="asset-description__textarea "
            name="how-a-description"
            v-model="transaction_description"
            ref="transaction_description"
            :class="[
              has_description === 'false' ? 'disabled' : '',
              error.transaction_description ? 'error' : ''
            ]"
          ></textarea>
          <div class="error-message" v-if="error.transaction_description">
            {{ error.transaction_description }}
          </div>
        </label>
      </div>
      <div class="asset-description__information">
        توضیحاتی که در این قسمت وارد می کنید به همراه تراکنش ارسال خواهد شد.
      </div>

      <div class="asset-description-section">
        <div class="asset-description__header">
          دارای کد امنیتی
        </div>
        <div class="radio">
          <input
            type="radio"
            id="radio"
            name="how-a-description"
            value="true"
            v-model="has_code"
          />
          <label for="radio">بلی</label>
        </div>
        <div class="radio">
          <label>
            <input
              type="radio"
              name="how-a-description"
              value="false"
              v-model="has_code"
            />
          </label>
          <label for="radio">خیر</label>
        </div>
      </div>
      <div class="asset-description__textarea-wrapper">
        <label>
          <textarea
            class="asset-description__textarea"
            name="how-a-description"
            ref="security_code"
            v-model="security_code"
            :class="[
              has_code === 'false' ? 'disabled' : '',
              error.security_code ? 'error' : ''
            ]"
          ></textarea>
          <div class="error-message" v-if="error.security_code">
            {{ error.security_code }}
          </div>
        </label>
      </div>
      <div class="asset-description__information">
        اگر تمایل دارید تراکنش به همراه کد امنیتی ارسال شود، کد امنیتی مورد نظر
        را در این قسمت وارد نمایید.
      </div>

      <div class="how-to-pay-section">
        <div class="how-to-pay__header">
          نحوه دریافت پول
        </div>
        <div class="how-to-pay__selected-section">
          <div class="how-to-pay__radio-wrapper">
            <div class="how-to-pay__radio">
              <input type="radio" id="gate" value="gate" v-model="pay_method" />
              <label class="radio-label" for="gate">درگاه بانکی</label>
            </div>
            <div class="how-to-pay__radio">
              <input
                type="radio"
                id="wallet"
                value="wallet"
                v-model="pay_method"
              />
              <label class="radio-label" for="wallet"
                >کیف پول (موجودی:
                <span>{{
                  item
                    ? Number(item.user_wallet_balance).toLocaleString()
                    : "----"
                }}</span
                >)</label
              >
            </div>
          </div>
          <div class="how-to-pay__information">
            با شارژ کیف پول میتوانید پرداخت های خود را بدون رفتن به درگاه بانکی
            پرداخت کنید.
          </div>
        </div>
        <div class="description-section">
          <div class="description-header">توضیحات</div>
          <label>
            <textarea
              class="description-textarea"
              name=""
              style="resize: none"
              v-model="description"
            ></textarea>
          </label>
        </div>
      </div>
      <div class="buy-button-wrapper" @click="submit">
        <Button class="btn-recharge-account">شارژ حساب</Button>
      </div>
    </div>
  </div>
</template>

<script>
import ConfirmPurchaseRequest from "@/components/Modals/ConfirmPurchaseRequest";
import SuccessAlert from "@/components/Modals/SuccessAlert";
import LackOfInventory from "@/components/Modals/LackOfInventory";
import axios from "axios";

export default {
  name: "AccountRechargeSystemPayment",
  props: {
    item: {
      required: true
    }
  },
  components: { SuccessAlert, ConfirmPurchaseRequest, LackOfInventory },
  data() {
    return {
      confirmPurchaseModal: false,
      successMessageModal: false,
      lackOfInventoryModal: false,
      has_description: "true",
      has_code: "true",
      transaction_description: "",
      security_code: "",
      description: "",
      pay_method: "gate",
      amount: null,
      tomman: null,
      wallet_address: null,
      error: {}
    };
  },
  methods: {
    submit() {
      this.error = {};
      if (!this.amount || this.amount == "0") {
        this.error.amount = "تعداد را وارد کنید";
        this.$refs.amount.focus();
        return false;
      }
      if (!this.tomman || this.tomman == "0") {
        this.error.tomman = "قیمت نباید خالی باشد";
        this.$refs.tomman.focus();
        return false;
      }
      if (!this.wallet_address) {
        this.error.wallet_address = "آدرس کیف پول را وارد کنید";
        this.$refs.wallet_address.focus();
        return false;
      }
      if (this.has_description === "true" && !this.transaction_description) {
        this.error.transaction_description = "توضیحات را وارد کنید";
        this.$refs.transaction_description.focus();
        return false;
      }
      if (this.has_code === "true" && !this.security_code) {
        this.error.security_code = "کد امنیتی را وارد کنید";
        this.$refs.security_code.focus();
        return false;
      }

      if (this.pay_method === "gate") {
        console.log("gate");
        return false;
      }

      let tomman = Number(String(this.tomman).replace(/[^0-9-.]/g, ""));

      if (this.pay_method === "wallet") {
        if (this.item.user_wallet_balance < tomman) {
          this.lackOfInventoryModal = true;
          return false;
        }

        this.confirmPurchaseModal = true;
      }
    },
    async confirmPurchaseModalHandle() {
      let tomman = Number(String(this.tomman).replace(/[^0-9-.]/g, ""));

      if (this.pay_method === "wallet") {
        let data = {
          crypto_id: this.item.id,
          cost: tomman,
          amount: this.amount,
          wallet_address: this.wallet_address,
          security_code: this.security_code,
          transaction_description: this.transaction_description,
          description: this.description
        };

        let loader = this.$loading.show();
        try {
          let response = await axios({
            url: `/panel/order?pay_method=wallet&type=charge&order_method=system_payment`,
            method: "POST",
            data: data
          });
          if (!response.data.status) {
            loader.hide();
            return false;
          }
          this.amount = 0;
          this.tomman = 0;
          this.wallet_address = null;
          this.transaction_description = null;
          this.security_code = null;
          this.description = null;
        } catch (error) {
          console.log(error);
        }
        loader.hide();
        this.confirmPurchaseModal = false;
        this.successMessageModal = true;
        setTimeout(() => {
          this.successMessageModal = false;
        }, 2000);
      }
    },
    toggleConfirmPurchaseRequest() {
      this.showBuyModal = !this.showBuyModal;
      this.showSuccessMassage = false;
      if (!this.showBuyModal) {
        this.showSuccessMassage = true;
        setTimeout(() => (this.showSuccessMassage = false), 2000);
      }
    },
    lackOfInventoryHandle() {
      this.lackOfInventoryModal = false;
    },
    setAmount(e) {
      // console.log(e);
      let value = typeof e !== "number" ? e.target.value : e,
        data = typeof e !== "number" ? e.data : e,
        reg = /^0$|^[1-9]\d*$|^\.\d+$|^0\.\d*$|^[1-9]\d*\.\d*$/;

      if (value[value.length - 2] === undefined) data = null;

      if (reg.test(value[value.length - 2] + data)) {
        if (value === null) value = "";
        if (!reg.test(value)) value.slice(0, -1);
        if (value === "") value = null;
      } else {
        value = value.replace(data, "");
      }

      this.amount = value;
      this.tomman = (this.item.purchase_price_from_us * value).toLocaleString();
      this.$emit("value", { amount: this.amount, tomman: this.tomman });
    },
    setTomman(e) {
      let value = typeof e !== "number" ? e.target.value : e,
        data = typeof e !== "number" ? e.data : e,
        reg = /^0$|^[1-9]\d*$|^\.\d+$|^0\.\d*$|^[1-9]\d*\.\d*$/;

      if (reg.test(data)) {
        if (reg !== null) {
          if (value === null) value = "";
          if (!reg.test(value)) value.slice(0, -1);
          if (value === "") value = null;
        }
      } else {
        value = value.replace(data, "");
      }

      this.tomman = value;
      this.amount = value / this.item.purchase_price_from_us;
      this.$emit("value", { amount: this.amount, tomman: this.tomman });
    }
  }
};
</script>

<style scoped>
.bitcoin-container {
  width: 100%;
  min-height: 743px;
  border-radius: 10px;
  background: rgba(3, 145, 0, 0.1);
  /*padding: 20px;*/
  padding: 5px;
}

.bitcoin-amount-input {
  width: 100%;
}

.amount-input__wrapper {
  display: flex;
  background: #fff;
  justify-content: space-between;
  padding: 3px 3px 3px 12px;
  border-radius: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
}

input {
  flex-grow: 1;
  padding: 0 10px;
  /* height: 100%; */
  outline: none;
  border: none;
  font-size: 16px;
}

.bitcoin-amount-details {
  display: flex;
  /*justify-content: space-between;*/
  flex-wrap: wrap;
  gap: 20px;
}

.bitcoin-amount-details__span {
  font-weight: normal;
  font-size: 12px;
  color: rgba(18, 18, 18, 0.7);
  display: flex;
  /*width: 100%;*/
}

.amount-input__symbol {
  color: rgba(18, 18, 18, 0.7);
}

.amount-input__image {
  width: 22px;
  height: 22px;
  display: none;
}

.amount-of-bitcoin-form-section {
  margin-bottom: 20px;
}

.amount-of-bitcoin-form-section__header {
  color: #121212;
  font-size: 16px;
  font-family: "Vazir Medium FD";
}

.purchase-price-from-site {
  margin-bottom: 20px;
}

.purchase-price-form-site_price {
  background: rgba(255, 255, 255, 0.5);
  text-align: end;
  padding: 3px 0 3px 27px;
  border-radius: 5px;
  color: rgba(18, 18, 18, 0.7);
}

.asset-description__header {
  font-weight: normal;
  font-size: 16px;
  line-height: 25px;
  color: #121212;
  font-family: "Vazir Medium FD";
}

.asset-description-section {
  display: flex;
  gap: 20px;
}

.wallet-address-section {
  margin-bottom: 20px;
}

.wallet-address-wrapper__header {
  font-size: 16px;
  color: #121212;
  font-family: "Vazir Medium FD";
}

.wallet-address-input {
  width: 100%;
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.8);
  padding: 4px 0 4px 12px;
  direction: ltr;
  color: rgba(18, 18, 18, 0.7);
}

.radio {
  display: flex;
  align-items: center;
  gap: 5px;
}

.asset-description__textarea {
  outline: none;
  border: none;
  height: 60px;
  width: 100%;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.8);
  resize: none;
  padding: 5px;
  /*margin-top: 5px;*/
}

.asset-description__information {
  font-weight: normal;
  font-size: 12px;
  line-height: 19px;
  color: rgba(18, 18, 18, 0.7);
  margin-bottom: 20px;
}

.transfer-fee-section {
  margin-bottom: 20px;
}

.transfer-fee-section {
  width: 100%;
}

.transfer-fee-wrapper {
  width: 100%;
  background: #fff;
  display: flex;
  padding: 10px;
  border-radius: 10px;
  border: 1px dashed #6b88d1;
  gap: 25px;
}

.wallet-address-section {
  margin-bottom: 20px;
}

.wallet-address-wrapper__header {
  font-size: 16px;
  color: #121212;
  font-family: "Vazir Medium FD";
}

.wallet-address-input {
  width: 100%;
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.8);
  padding: 4px 0 4px 12px;
  direction: ltr;
  color: rgba(18, 18, 18, 0.7);
}

.how-to-pay__header {
  font-size: 16px;
  color: #121212;
  margin-bottom: 30px;
  font-family: "Vazir Medium FD";
}

.how-to-pay__radio-wrapper {
  display: flex;
  gap: 90px;
}

.how-to-pay__radio {
  display: flex;
  align-items: center;
  gap: 5px;
}

label {
  font-size: 14px;
}

.how-to-pay__information {
  font-size: 12px;
  margin-top: 10px;
  color: #121212;
  font-family: "Vazir Medium FD";
}

.how-to-pay__selected-section {
  margin-bottom: 20px;
}

.description-textarea {
  outline: none;
  border: none;
  padding: 5px;
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.8);
  width: 100%;
  height: 111px;
  /*padding: 10px;*/
}

.description-header {
  font-weight: normal;
  font-size: 16px;
  font-family: "Vazir Medium FD";
}

.buy-button {
  margin: 25px auto;
  width: 100%;
  padding: 5px 0;
  cursor: pointer;
}

.btn-recharge-account {
  background-color: #039100;
  color: #ffffff;
  font-weight: 500;
  font-size: 20px;
  line-height: 31px;
  outline: none;
  border: none;
  padding: 5px 0;
  width: 100%;
  border-radius: 5px;
  cursor: pointer;
}

.buy-button-wrapper {
  margin-top: 25px;
}

.disabled {
  opacity: 0.3;
}
.disabled:after {
  width: 100%;
  height: 100%;
  position: absolute;
}

.radio-label {
  font-family: "Vazir Medium FD";
}

.error {
  border: 1px solid #ff6b6b;
}

.error-message {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: #ff6b6b;
}

@media (min-width: 768px) {
  .bitcoin-container {
    padding: 20px;
  }
}

@media (min-width: 540px) {
  .amount-input__image {
    display: block;
  }
}
</style>
